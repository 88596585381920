import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,[_vm._v(" Ticket di "+_vm._s(_vm.loggedUser.fullName)+" ")]),_c(VCardText,[[_c(VTimeline,{attrs:{"reverse":""}},_vm._l((_vm.ticketData.ticket_messages),function(message,idx){return _c(VTimelineItem,{key:'A'+idx,attrs:{"large":"","right":_vm.isLoggedUser(message.user_id) ? true : null,"left":_vm.isLoggedUser(message.user_id) ? null : true},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('procedure-users-avatar',{attrs:{"user":message.user,"size":50}})]},proxy:true},{key:"opposite",fn:function(){return [_c('span',[_vm._v(_vm._s(message.createdAtTimeFormatted))])]},proxy:true}],null,true)},[_c(VCard,{staticClass:"elevation-3",attrs:{"color":""}},[_c(VCardText,[_vm._v(_vm._s(message.description))])],1)],1)}),1)]],2),_c(VCardActions,{staticClass:"pl-4 pr-4"},[_c(VRow,[_c(VCol,{attrs:{"cols":"11"}},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.sendNewMessage.apply(null, arguments)}}},[_c(VTextarea,{attrs:{"label":"Nuovo messaggio","rows":"2","required":"","filled":"","auto-grow":"","disabled":_vm.loading.sendButton},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c(VCol,{attrs:{"cols":"1"}},[_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary","dark":"","loading":_vm.loading.sendButton},on:{"click":_vm.sendNewMessage}},[_vm._v(" INVIA ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }