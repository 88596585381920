<template>
  <div>
    <v-card>
      <v-card-title>
        Ticket di {{ loggedUser.fullName }}
      </v-card-title>
      <v-card-text>
        <template>
          <v-timeline
            reverse
          >
            <v-timeline-item
              v-for="(message,idx) in ticketData.ticket_messages"
              :key="'A'+idx"
              large
              :right="isLoggedUser(message.user_id) ? true : null"
              :left="isLoggedUser(message.user_id) ? null : true"
            >
              <template v-slot:icon>
                <procedure-users-avatar
                  :user="message.user"
                  :size="50"
                ></procedure-users-avatar>
              </template>
              <template v-slot:opposite>
                <span>{{ message.createdAtTimeFormatted }}</span>
              </template>
              <v-card
                class="elevation-3"
                color=""
              >
                <v-card-text>{{ message.description }}</v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </template>
      </v-card-text>
      <v-card-actions
        class="pl-4 pr-4"
      >
        <v-row>
          <v-col
            cols="11"
          >
            <v-form
              ref="form"
              @submit.prevent="sendNewMessage"
            >
              <v-textarea
                v-model="form.description"
                label="Nuovo messaggio"
                rows="2"
                required
                filled
                auto-grow
                :disabled="loading.sendButton"
              ></v-textarea>
            </v-form>
          </v-col>
          <v-col
            cols="1"
          >
            <v-btn
              class="ma-2"
              color="primary"
              dark
              :loading="loading.sendButton"
              @click="sendNewMessage"
            >
              INVIA
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import ProcedureUsersAvatar from '@/components/procedure/procedure-users-avatar.vue'

export default {
  name: 'TicketDetail',
  components: {
    ProcedureUsersAvatar,
  },
  data() {
    return {
      is_show: false,
      ticketData: {},
      form: {},
      loading: {
        sendButton: false,
      },
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
        {
          text: 'Ticket assistenza',
          to: '/tickets',
        },
        {
          text: 'Dettaglio',
          to: `/ticket/${this.$route.params.tid}`,
        },

      ],
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'ticketsList']),
  },
  mounted() {
    this.getData()
    this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
  },
  methods: {
    async getData() {
      const ticketId = parseInt(this.$route.params.tid, 10)
      this.ticketData = await this.ticketsList.find(item => item.id === ticketId)
    },
    async reloadData() {
      const ticketId = parseInt(this.$route.params.tid, 10)
      await this.$store.dispatch('getTicket', ticketId)
      this.ticketData = await this.ticketsList.find(item => item.id === ticketId)
    },
    async sendNewMessage() {
      if (this.$refs.form.validate()) {
        try {
          this.loading.sendButton = true
          await this.$api.createTicketMessage(this.form, this.$route.params.tid)
          this.$refs.form.reset()
          await this.$store.dispatch('set_alert', { msg: 'Messaggio inviato con successo', type: 'success' })
          await this.reloadData()
          this.loading.sendButton = false
        } catch (e) {
          this.loading.sendButton = false
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    isLoggedUser(uid) {
      return this.loggedUser.id === uid
    },
  },
}
</script>

<style scoped>

</style>
